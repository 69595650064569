// import { useState } from 'react';

import { faChartSimple, faCode, faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef } from "react";
import { BsPersonFillCheck,BsLayoutWtf } from 'react-icons/bs';
import { ObserverContext } from "../../Contexts/ObserverContext";
function Startcounter() {
   const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // set value in context for section = team.
        setActiveSection('accomplishments');
        console.log('this is updated section',activeSection)
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return (
    // <p>This is Startcounter Page</p>
    <section className="counter-section" id="accomplishments" ref={ref}>
   <div className="container">
      <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{ visibility: "hidden", animationDelay: "300ms", animationName: "none"}}>Our Accomplishments</h2>
      <p className="wow fadeInDown" data-wow-delay="300ms" style={{ visibility: "hidden", animationDelay: "300ms", animationName: "none"}}>Fostering Relationships while moving Forward on our success Trajectory</p>
      <div className="row no-gutters">
         {/* <!-- Counter-1 --> */}
         <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
            <div className="counter">
               <div className="media">
                  {/* <i className="fas fa-chart-bar"></i> */}
                  <FontAwesomeIcon icon={faUser} size="2xl" />
                  <div className="media-body mt-4 mt-md-0">
                     <span className="timer count-title count-number count">150+</span>
                     <p>Clients Served</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Counter-2 --> */}
         <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
            <div className="counter">
               <div className="media">
                  {/* <i className="far fa-clock"></i> */}
                  <FontAwesomeIcon icon={faChartSimple} size="2xl" />
                  <div className="media-body mt-4 mt-md-0">
                     <span className="timer count-title count-number count">200+</span>
                     <p>Projects Done</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Counter-3 --> */}
         <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInRight" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
            <div className="counter">
               <div className="media">
                  {/* <i className="far fa-star"></i> */}
                  <BsPersonFillCheck style={{fontSize:'30px', color:'black'}}></BsPersonFillCheck>
                  <div className="media-body mt-4 mt-md-0">
                     <span className="timer count-title count-number count">50+</span>
                     <p>Industry Experts</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Counter-4 --> */}
         {/* <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 wow fadeInRight" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
            <div className="counter">
               <div className="media">
                  <i className="far fa-heart"></i>
                  <div className="media-body mt-4 mt-md-0">
                     <span className="timer count-title count-number count">735</span>
                     <p>Happy Clients</p>
                  </div>
               </div>
            </div>
         </div> */}
      </div>
   </div>
</section>
  );
}

export default Startcounter;